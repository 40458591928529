import styled from "styled-components";

export const ContainerScene = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;

`;
